<template>
  <Loader :show="loading"/>
  <HeaderHome />
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">

        <div class="col-md-12 top-voltar">
          <a href="javascript:history.back(-1);" title="Voltar">
            <span class="glyphicon glyphicon-chevron-left"></span> Voltar
          </a>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-xs-10">
              <h1>Detalhe Inscrição # <span>{{id}}</span></h1>
            </div>
            <div class="col-xs-2">
                <a :href="'/minha-conta/'+id+'/impressao/'" class="btn btn-success" target="_blank">
                  <span class="glyphicon glyphicon-print"></span> Imprimir
                </a>
            </div>
          </div>
          
          <p class="text-justify">Confira abaixo os detalhes da sua inscrição.</p>
          <div class="row">
            <div class="col-md-12">

              <h3>Dados pessoais</h3>
              <p>
                <b>Nome:</b> {{order.client.full_name}}
                <br><b>CPF:</b> {{order.client.cpf}}
                <br><b>Tel/Cel:</b> {{order.client.telephone}} <span v-if="order.client.cellphone">/</span> {{order.client.cellphone}}
                <br><b>Endereço:</b>
                <br>{{order.client.street}}, {{order.client.number}}
                <br>{{order.client.neighborhood}} - {{order.client.city}}/{{order.client.state}}
              </p>

              <h3>Dados do evento</h3>
              <p><b>Evento:</b> {{order.event.name}}
                <br><b>Data de realização:</b> {{order.event.start_at}} às {{order.event.largada}}
                <br><b>Cidade/UF:</b> {{order.event.city_id}} - {{order.event.state_id}}
                <br><b>Local:</b> {{order.event.local}}</p>

              <h3 v-if="order.kit">Retirada do Kit</h3>
              <div v-if="order.kit" v-html="order.kit"></div>

              <h3>Dados da inscrição:</h3>
              <p>
                <b>Modalidade:</b> {{order.inscription.modalidade}}
                <br><b>Lote:</b> {{order.inscription.lote}}
                <br><b>Kit:</b> {{order.inscription.kit}}
                <br><b>Tamanho:</b> {{order.inscription.tamanho}}

                <span v-if="order.agendamento != null">
                  <br><b>Agendamento:</b> {{order.agendamento}}
                </span>
              </p>
              <p>
              </p>

              <h3>Status do pedido:</h3>
              <p>
                <b>Status:</b> {{status_pay[order.order.status]}}
              </p>
              <p v-if="order?.order?.boleto != null && order?.order?.boleto.search('pix') == -1">
                <b>Boleto :</b> <a :href="order.order?.boleto" target="_blank"> Abrir o BOLETO</a>
              </p>
              <p v-if="order.order?.boleto != null && order?.order?.boleto.search('pix') >= 0">
                <b>QR code do Pix</b><br/><br/>
                <img :src="order.order?.boleto" class="border-pix"><br/>
                <small style="font-size: 13px;">Se você ja efetuou o pagamento basta aguardar,<br/> em caso de duvidas fale com nosso time pelo nosso chat.</small>
              </p>

              <p>
              </p>

              <p>Importante: seu código é a identificação do seu kit. Portanto, tenha ele em mãos na retirada.</p>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-xs-12 col-md-6 text-right">
              <button type="button" class="btn btn-success" @click.prevent="send_mail"><span class="glyphicon glyphicon-file"></span> 2ª via por e-mail</button>
            </div>
            <div class="col-xs-12 col-md-6 ">
              <a :href="'/minha-conta/'+id+'/impressao/'" class="btn btn-success" target="_blank">
                <span class="glyphicon glyphicon-print"></span> Imprimir
              </a>
            </div>
          </div>
          <Atalhos/>
        </div>
      </div>
    </div>
  </div>
  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script >
import { defineComponent } from "vue";
import HeaderHome from "../components/HeaderHome.vue";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Atalhos from "../components/Atalhos";
import Loader from "../components/Loader";
import Orders from "../api/Orders";
import Auth from "../api/Auth";
import Swal from "sweetalert2";

export default defineComponent({
  name: "VerDetalhesPages",
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Atalhos,
    Newsletter,
    Loader
  },
  data() {
    return {
      id:"",
      status_pay:{
        "1":	"Aguardando pagamento",
        "2":	"Em análise",
        "3":	"Pago",
        "4":	"Disponível",
        "5":	"Em disputa",
        "6":	"Devolvida",
        "7":	"Cancelada"
      },
      order: {
        "agendamento":null,
        "client": {
          "id": "",
          "state": "",
          "city": "",
          "full_name": "",
          "cpf": "",
          "telephone": "",
          "cellphone": "",
          "street": "",
          "number": "",
          "neighborhood": ""
        },
        "event": {
          "id": "",
          "state_id": "",
          "city_id": "",
          "name": "",
          "image": "",
          "start_at": "",
          "largada": "",
          "local": ""
        },
        "kit": "",
        "inscription": {
          "modalidade": "",
          "lote": "",
          "kit": "",
          "tamanho": ""
        },
        "order":{
          "status":"",
          "boleto":null
        }
      },
      loading:true,
      slug: "",
    };
  },
  methods:{
    get_infos(){
      Orders.get_events(this.id,Auth.token()).then((request)=>{
        if(request.status == 200){
          this.order = request.data;
          this.loading = false;
          
    console.log(request.data);
        }
      })
    },
    send_mail(){
      this.loading = true;

      Orders.send_mail(this.id,Auth.token()).then((request)=>{
        if(request.status == 200){
          Swal.fire({
                icon: 'success',
                title: 'E-mail enviado com sucesso!.',
                showConfirmButton: true,
                timer: 5000,
                timerProgressBar:true,
              }
          );

          this.loading = false;
        }
      })
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.get_infos();
  },
  updated() {
    if(this.id != this.$route.params.id){
      this.id = this.$route.params.id;
      this.loading = true;
      this.get_infos();
    }
  }
});
</script>

<style scoped>
.mt-4{
  margin-top: 30px;
}
.mb-4{
  margin-bottom: 30px;
}
.top-voltar{
  margin-bottom: 15px;
}
.top-voltar a{
  text-decoration: none;
  color: #d31f28;
}
.border-pix{
  border: 1px solid #d31f28;
  border-radius: 5px;
}
</style>
